import useAuthStore from '../store/auth';
import { USER_LEVELS } from '~/config';

export default defineNuxtRouteMiddleware((to, from) => {
  const authStore = useAuthStore();

  if (!authStore.user?.isAdmin && authStore.user?.userLevel !== USER_LEVELS.Owner) {
    return navigateTo('/');
  }

  return true;
});
